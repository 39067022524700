import 'leaflet';
import ('leaflet-image');
import '@geoman-io/leaflet-geoman-free';  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
// eslint-disable-next-line no-unused-vars
import LeafleatMeasurePath from 'leaflet-measure-path';
// eslint-disable-next-line no-unused-vars
import LeafleatMeasure     from '../../node_modules/leaflet-linear-measurement/src/Leaflet.LinearMeasurement.js';
//require('./leaflet-polygon.fillPattern.js');
import turfPointToLineDistance from '@turf/point-to-line-distance'
import * as turfHelpers   from '@turf/helpers'

var L = window.L;
class LfMap {
    constructor(mapContainer) {
      this.turfPointToLineDistance      = turfPointToLineDistance;
      this.turfHelpers   = turfHelpers;
      
      this.layerUrl = 'https://a.tiles.technosig.fr/hqLrnsIjnSnE8Al/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png';
      this.mapContainer = mapContainer;
      this.baseLayerMap = {}
      this.baseLayerMap.osm = this.setLayer('base'); //this.offlineLayerMap = this.setLayer('offline');
      this.Map          = null;
      this.arrayOfLatLngs = [];
      this.leaflet       = window.L;
      this.defautLatLng  = [47.47479783478821, 1.0530929565429689];
      this.markerLoc     = [0, 0];
      this.markerDrag    = null;
      this.siteMarkers   = L.featureGroup();
      this.suppMarkers   = L.layerGroup();
      this.layerList     = [];
      this.siteLayer     = {};
      this.component     = {};
      this.popupInfo     = {};
      this.popupOpen     = '';
      this.liveInfo      = {};
      this.envLayer      = L.layerGroup();
      this.layerDraw     = L.layerGroup();
      this.layerEditId   = -1;
      this.layerSlected  = -1;
      this.svgIcon       = {
        green: '<div class="marker-svg marker-green"><center></center></div>',
        blue: '<div class="marker-svg marker-blue"><center></center></div>',
        orange: '<div class="marker-svg marker-orange"><center></center></div>',
        red: '<div class="marker-svg marker-red"><center></center></div>'
      };
      this.leafletImage = function(){ return '' };
      this.mapCenter      = null;
      this.curentSheet    = L.featureGroup();
    }
  
    display() {
      const mapOptions = {
        zoom: 6,
        center: this.defautLatLng,
        layers: [this.baseLayerMap.osm],
        preferCanvas: false
      };
      this.Map = new this.leaflet.map(this.mapContainer, mapOptions);
      this.Map.pm.setLang('fr');
      this.layerDraw.addTo(this.Map);

      L.control.scale( { imperial: false, position: 'bottomleft' } ).addTo(this.Map);
      this.Map.on('popupclose', () => {
        if( this.siteLayer[ this.popupOpen ] !== undefined ){
          this.siteLayer[ this.popupOpen ].setStyle( { weight: 3 } );
          this.popupOpen = '';
        }
      });
      this.Map.on('click', (e) => {
        var popup = new L.Popup();
        var popupContent = "Coordonnée GPS :<br />";
        popupContent    += 'lat: ' + e.latlng.lat + "<br />";
        popupContent    += 'lng: ' + e.latlng.lng + "<br />";
        popup.setLatLng(e.latlng);
        popup.setContent(popupContent);
        this.Map.openPopup(popup);
      });
      
      if( Object.prototype.toString.call( this.mapCenter ) === '[object Array]' ){
        this.Map.setView(new L.LatLng(this.mapCenter[0], this.mapCenter[1]), 20);
      }

      // drawing
      //this.Map.on('pm:drawstart', (e) => {
      //  console.log(e)
      //})
      // leaflet measure controls
      this.Map.addControl(new L.Control.LinearMeasurement({
        unitSystem: 'metric',
        color: '#FF0080',
        type: 'line',
        position: 'bottomright'
      }));

      //L.tileLayer.wms('http://ows.mundialis.de/services/service?', {layers: 'TOPO-OSM-WMS'}).addTo(this.Map);
      //let tmp = L.tileLayer('https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=PROTECTEDSITES.MNHN.RESERVES-REGIONALES&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}').addTo(this.Map);
      //tmp.setOpacity(0.5)
      return this.Map;
    }

    // Add Draw control
    addPmControl(layerId){
      // Leaflet PM
      var options = {
        position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
        drawMarker: false, // adds button to draw markers
        drawPolyline: true, // adds button to draw a polyline
        drawRectangle: true, // adds button to draw a rectangle
        drawPolygon: true, // adds button to draw a polygon
        drawCircle: false, // adds button to draw a cricle
        drawCircleMarker: false,
        drawText: false,
        cutPolygon: false, // adds button to cut a hole in a polygon
        editMode: false, // adds button to toggle edit mode for all layers
        removalMode: false, // adds a button to remove layers
        dragMode: false,
        rotateMode: false
      };

      this.layerEditId = layerId;
      this.Map.pm.addControls(options);
      //this.showHideMeasurements(layerId, true);
    }
  
    // Remove Draw control
    removePmControl(){
      this.Map.pm.removeControls();
      if( this.Map.pm.globalDragModeEnabled() ){ this.Map.pm.toggleGlobalDragMode(); }
      if( this.Map.pm.globalRemovalEnabled() ){ this.Map.pm.toggleGlobalRemovalMode(); }
      if( this.Map.pm.globalEditEnabled() ){ this.Map.pm.disableGlobalEditMode(); }
      //map.pm.disableDraw('Polygon');
      //this.showHideMeasurements(this.layerEditId, false)
      this.layerEditId = -1;
    }

    setLayer(value) {
      switch(value) {
        case 'base':
          return this._setBaseLayer();
        case 'offline':
          return this._setOfflineLayer();
        default:
          return this._setBaseLayer();
      }
    }
  
    _setBaseLayer() {
      return L.tileLayer('https://{s}.tiles.technosig.fr/' + process.env.VUE_APP_IGN_KEY + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png', { //this.layerUrl
        attribution: 'TECHNOSIG',
        id: 'osm'
      });
    }
  
    _setOfflineLayer() {
      return L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { //this.layerUrl
        attribution: 'TECHNOSIG - Offline',
        id: 'osm cache'
      });
    }
  
    setInitialCenter(){
      this.Map.setView(this.defautLatLng)
      this.Map.setZoom(9); //this.Map.panTo(this.defautLatLng, {animate: true, duration: 1.0});
    }
  
    removeSiteMarker(){
      this.siteMarkers.clearLayers();
    }
  
    // Remet les icones dans l'état initial
    clearSiteMarker(){
      let val
      for ( val in this.siteMarkers._layers ) {
        if( this.siteMarkers._layers[val]._active ){
          this.siteMarkers._layers[val].setIcon( this.buildIcon('green') );
        }
      }
      if( this.suppMarkers ){
        this.Map.removeLayer(this.suppMarkers);
      }
    }
  
    // Ajouter un marqeur de géolocalisation
    addLocMarker(pos) {
      if( pos.lat == 0 || pos.lng == 0 ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( this.markerDrag == null || this.markerDrag == undefined ){
        this.markerDrag = this.buildMarker( { draggable: true, type: 'blue', latLng: [pos.lat, pos.lng] } );
        this.markerDrag.addTo(this.Map);
      } else {
        this.markerDrag.setLatLng( new this.leaflet.LatLng(pos.lat, pos.lng) );
      }
      //this.Map.setView( new this.leaflet.LatLng(pos.lat, pos.lng), 9 );
    }
  
    buildMarker(obj) {
      let title = obj.title || '';
      let popup = obj.popup || '';
      let draggable = obj.draggable || false
      let marker = this.leaflet.marker(obj.latLng, {
        title: title,
        icon: this.buildIcon(obj.type),
        draggable: draggable
      });
      marker.on('dragend', () => {
        var position = marker.getLatLng();
        this.markerLoc = [ position.lat, position.lng ];
      });
      if( popup.length > 0 ){
        marker.bindPopup(popup);
      }
  
      return marker;
    }
  
    buildIcon(type) {
      let html = '<div class="marker-svg marker-blue"><center></center></div>';
      switch(type) {
        case 'blue':
          html = this.svgIcon['blue'];
          break;
        case 'green':
          html = this.svgIcon['green'];
          break;
        case 'orange':
          html = this.svgIcon['orange'];
          break;
        case 'red':
          html = this.svgIcon['red'];
          break;
        default:
          html = this.svgIcon['blue']
      }
      return this.leaflet.divIcon({ 
        html: html, 
        iconSize: [50, 80], 
        iconAnchor: [25, 79], 
        popupAnchor: [-2, -60]
      });
    }
    displayGeojson(data, id){
      var self = this;
      function onEachFeature(feature, layer) {
        //let testBound = layer._bounds.getCenter()
        //console.log( feature.properties.IDENTIFICA + ";" + testBound.lat + ';' + testBound.lng )
        layer.feature.properties = feature.properties
        layer.bindPopup( () => {
          self.popupInfo = feature.properties;
          self.updatePopupInfo(id, feature.properties);
          return self.component.popupContent;
        }, { maxWidth : 500} );

        if( layer.setStyle ){
          layer.setStyle( { color: data.lay_color, fillColor : data.lay_color, opacity:1, fillOpacity:0.2 } );
        }
        layer.on('click', (e) => {
          if( self.layerEditId == id ){
            e.target.closePopup();
            if( layer.pm.enabled() ){
              layer.pm.disable()
            } else {
              layer.pm.enable();
            }
          }
          layer._lastWeight = (layer._lastWeight == undefined) ? layer.options.weight : layer._lastWeight;
          self.popupOpen = feature.properties.nom_site;

          if( self.layerSlected == layer._leaflet_id ){
            self.layerSlected = -1
            layer.setStyle( { color: data.lay_color, fillColor : data.lay_color, opacity:1, fillOpacity:0.2 } );
          } else {
            if( self.layerSlected > -1 ){
              self.Map._layers[self.layerSlected].setStyle( { color: data.lay_color, fillColor : data.lay_color, opacity:1, fillOpacity:0.2 } );
            }
            self.layerSlected = layer._leaflet_id
            layer.setStyle( { weight: 3, color: 'black' } );
          }
        });
        layer.on('contextmenu', () => {
          if( self.layerSlected == layer._leaflet_id ){
            self.layerSlected = -1
            layer.setStyle( { color: data.lay_color, fillColor : data.lay_color, opacity:1, fillOpacity:0.2 } );
          } else {
            if( self.layerSlected > -1 ){
              self.Map._layers[self.layerSlected].setStyle( { color: data.lay_color, fillColor : data.lay_color, opacity:1, fillOpacity:0.2 } );
            }
            self.layerSlected = layer._leaflet_id
            layer.setStyle( { weight: 3, color: 'black' } );
          }
        });

        layer.on('mouseover', () => {
          self.updateLiveInfo(id, feature.properties);
        });
        layer.on('mouseout', () => {
          self.updateLiveInfo(id, feature.properties, false);
        });
      }
      this.layerList[id] = L.geoJSON(data.lay_geojson, { 
        onEachFeature: onEachFeature,
        pointToLayer: function (feature, latlng) {
          return L.circle(latlng, { color: data.lay_color, fillColor: data.lay_color, fillOpacity: 1, radius: 0.1 });
        }
      });
      this.Map.addLayer(this.layerList[id]);
      return true;
    }

  
    // Afficher / cacher un layer
    showHideLayer( layerTab, state ){
      if( layerTab == 'sites' ){
        for( var prop in this.siteLayer ){
          if( !state && this.siteLayer[prop] !== undefined && this.Map.hasLayer( this.siteLayer[prop] )) {
            this.Map.removeLayer( this.siteLayer[prop] );
          } else if( state && this.siteLayer[prop] !== undefined ){
            this.Map.addLayer( this.siteLayer[prop] );
          }
        }
      }
      if( !state && this.layerList[layerTab] !== undefined && this.Map.hasLayer( this.layerList[layerTab] )) {
        this.Map.removeLayer( this.layerList[layerTab] );
      } else if( state && this.layerList[layerTab] !== undefined ){
        this.Map.addLayer( this.layerList[layerTab] );
      }
    }

    fitToTrackingSheet(id, layerInfo, lastSheet){
      var isFind = false
      this.layerList[id].eachLayer( ( layer ) => {
        if( layerInfo.lay_field ){
          layerInfo.lay_field.forEach( elem => {
            if( layer.feature && layer.feature.properties[ elem.source ] ){
              let code    = layer.feature.properties[ elem.source ].split("-")
              let mc2code = layer.feature.properties[ elem.source ]
              if(code.length > 3){
                  mc2code = code[0] + '-' + code[1] + '-' + code[2] + '-' + code[3] 
              }
              if( lastSheet['trs_mc2_code'] == mc2code ){
                this.layerSlected = layer._leaflet_id
                layer.setStyle({ color:'black' }); //fill:'url(hatch.gif)',
                this.curentSheet.addLayer(layer)
                isFind = true
              }
            }
          })
        }
      })
      if( isFind ){
        let bounds   = this.curentSheet.getBounds();
        this.Map.fitBounds(bounds);
      }
    }
  
    fitToLayer( id ){
      var bounds   = this.layerList[id].getBounds();
      this.Map.fitBounds(bounds);
    }
    // Changer la couleur d'un calque et de ses enfant
    setLayerColor(id, color){
      if( this.layerList[id] !== undefined ){
        if( typeof this.layerList[id].setStyle === "function" ){
          this.layerList[id].setStyle( { color: color, fillColor :color } );
        }
      }
    }
    // Changer la transparence
    setLayerTransparency(id, percent){
      let opacity     = percent
      let fillOpacity = ( percent > 0 ) ? percent / 5 : 0
      if( this.layerList[id] !== undefined ){
        if( typeof this.layerList[id].setStyle === "function" ){
          this.layerList[id].setStyle( { opacity: opacity, fillOpacity : fillOpacity } );
        }
      }
    }
    // Ajouter un marqeur Projet
    addSiteMarker(sites) {
      let i = 0
      let key, val
      let marker, popup
      for (key = 0; (val = sites[key]) !== undefined; key++) {
        if( val.sit_lat !== undefined && val.sit_lat !== 0 ){
          popup  = '<div style="width:241px;">';
          popup += '<div class="ma-2">';
          popup += '<div class="title blue--text">' + val.sit_name + '<br /></div>';
          popup += '<div class="body-2 green--text mb-2"><i class="v-icon mr-1 mdi mdi-home-modern theme--light"></i>' + val.agc_name + '<br /></div>';
          popup += '<hr class="v-divider theme--light">'
          popup += '<div class="mt-2 text-xs-center">';
          popup += '<a class="v-btn v-btn--outline v-btn--depressed v-btn--small grey" href="https://www.google.com/maps/dir/?api=1&destination=' + val.sit_lat + ',' + val.sit_lng + '" target="_blank"><i class="v-icon mr-1 mdi mdi-directions theme--light"></i></a>';
          popup += '</div>';
          popup += '</div>';
          popup += '<a class="v-btn v-btn--depressed theme--light v-btn--block success" type="button" href="/#/carte/site/' + val.sit_id + '">Voir</a>';
          popup += '</div>';
          marker = this.buildMarker( { title: val.sit_name, popup: popup, type: 'green', latLng: [val.sit_lat, val.sit_lng] } );
          marker._sit_id  = val.sit_id;
          marker._active   = false;
          this.siteMarkers.addLayer(marker);
          i++;
        }
      }
          if(i > 0){
        this.Map.addLayer(this.siteMarkers);
        this.Map.fitBounds( this.siteMarkers.getBounds() );
          }
    }
  
    moveLocMarker(latLng){
      this.markerDrag.setLatLng( new this.leaflet.LatLng(latLng.lat, latLng.lng) );
      this.Map.flyTo([ latLng.lat, latLng.lng ], 17);
    }
  
    updateMap() {
      let bounds = new this.leaflet.LatLngBounds(this.arrayOfLatLngs);
      this.Map.fitBounds(bounds);
    }
  
    focusOnUser(position) {
      console.warn(position);
      const latlng = this.leaflet.latLng(position[0], position[1]);
      const marker = this.leaflet.marker(new this.leaflet.LatLng(position[0], position[1]), {
        title: 'Vous etes ici'
      });
      const circle = this.leaflet.circle(new this.leaflet.LatLng(position[0], position[1]), {
        color: '#00D1B2',
        fillColor: '#00D1B2',
        fillOpacity: 0.5,
        radius: 500
      });
      // clear map
      this.Map.removeLayer(marker);
      this.Map.removeLayer(circle);
      // adjusts zoom and position
      this.Map.panTo(latlng);
      this.Map.setZoom(15);
      // add to map
      marker.addTo(this.Map);
      marker.bindPopup(`You are here!`);
      // add to map
      circle.addTo(this.Map);
    }
  
    // Ajouter une zone (cercle)
    addCircleZone(pos){
      if( pos.lat == 0 || pos.lng == 0 || pos.lat == undefined ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( this.circleMarker == null || this.circleMarker == undefined ){
        this.circleMarker = L.circle([pos[1], pos[0]], {
            color: '#2196F3',
            fillColor: '#2196F3',
            fillOpacity: 0.5,
            radius: 10000
        }).addTo(this.Map);
      } else {
        this.circleMarker.setLatLng( [pos[1], pos[0]] );
      }
      setTimeout( () => {
        this.Map.removeLayer(this.circleMarker)
      }, 1500);
    }
  
    panTo(pos){
      this.Map.setView( [pos[1], pos[0]], 12, {
        pan: { animate: true, duration: 1.50 },
        zoom: { animate: true }
      });
    }
  
    setMap(value) {
      switch(value) {
        case 'offline':
          this.Map.removeLayer(this.baseLayerMap.osm);
          this.Map.addLayer(this.offlineLayerMap);
        break;
  
        case 'online':
          this.Map.removeLayer(this.offlineLayerMap);
          this.Map.addLayer(this.baseLayerMap.osm);
  
          this.enableMap();
        break;
      }
    }
  
    setTileLayer( tileLayer, id ){
      if( !tileLayer.active ){
        this.Map.removeLayer(this.baseLayerMap[id]);
      } else {
        this.baseLayerMap[id] = L.tileLayer(tileLayer.link, {
          attribution: 'TECHNOSIG',
          id: 'osm'
        });
        this.baseLayerMap[id].setOpacity(tileLayer.opacity / 100);
        this.Map.addLayer(this.baseLayerMap[id]);
      }
    }
    setTileStyle( tileLayer, id ){
      this.baseLayerMap[id].setOpacity(tileLayer.opacity / 100);
    }
  
      // Calcul de distance GPS (direct)
    distance = function(lat_a, lon_a, lat_b, lon_b) {
      let a = Math.PI / 180;
      let lat1 = lat_a * a;
      let lat2 = lat_b * a;
      let lon1 = lon_a * a;
      let lon2 = lon_b * a;
  
      let t1 = Math.sin(lat1) * Math.sin(lat2);
      let t2 = Math.cos(lat1) * Math.cos(lat2);
      let t3 = Math.cos(lon1 - lon2);
      let t4 = t2 * t3;
      let t5 = t1 + t4;
      let rad_dist = Math.atan(-t5/Math.sqrt(-t5 * t5 +1)) + 2 * Math.atan(1);
  
      return Math.round( ((rad_dist * 3437.74677 * 1.1508) * 1.6093470878864446) * 100 ) / 100 ;
    }
  
    enableMap() {
      this.Map.dragging.enable();
      this.Map.touchZoom.enable();
      this.Map.doubleClickZoom.enable();
      this.Map.scrollWheelZoom.enable();
      this.Map.boxZoom.enable();
      this.Map.keyboard.enable();
  
      if (this.Map.tap) {
        this.Map.tap.enable();
      }
    }

    // Retourner le dessins (main)
    getlayerDraw(id){

      return this.layerList[id].toGeoJSON(15);
    }

    computeLayerDistance(ids){
      return new Promise( (resolve) => {
        let distances = []

        let line   = ids[0]
        let parcel = ids[1]
        let lineString
        if( Object.keys(this.layerList[ids[0]]._layers).length > 2 ){
          parcel = ids[0]
          line   = ids[1]
        }

        this.layerList[ line ].eachLayer( ( layer ) => {
          lineString  = this.turfHelpers.lineString( layer.feature.geometry.coordinates )
        })

        this.layerList[ parcel ].eachLayer( ( layer ) => {
          let point = layer.getBounds().getCenter();
          let poly  = this.turfHelpers.point([point.lng, point.lat]);
          var distance = this.turfPointToLineDistance(poly, lineString);
          distances.push({ id: ids[0], distance: distance, properties: layer.feature.properties })
        })
        resolve(distances)
      })
    }

    showHideMeasurements(id, isDisplayed) {
      const options = {
        formatDistance: (d) => {
          d = d * 1;
          return Math.round(d * 100) / 100 + ' ' + 'm';
        },
        formatArea: (d) => {
          d = d * 1;
          return Math.round(d * 100) / 100 + ' ' + 'm²';
        }
      };
      if( id >= 0 ){
        this.layerList[id].eachLayer((layer) => {
          if(isDisplayed){
            layer.showMeasurements(options);
          }
        })
      }
    }

  }
  
  export default LfMap;